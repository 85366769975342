import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerCategory() {
    var url = ConfigAPI.baseURL + "services-category" + Session.getToken();
    return instance.get(url);
  },
  mostrarCategory(id) {
    var url = ConfigAPI.baseURL + "services-category/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarCategory(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "services-category" + Session.getToken();
    return instance.post(url,params);
  },
  editarCategory(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "services-category/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarCategory(id) {
    var url = ConfigAPI.baseURL + "services-category/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  obtenerServices() {
    var url = ConfigAPI.baseURL + "services" + Session.getToken();
    return instance.get(url);
  },
  mostrarServices(id) {
    var url = ConfigAPI.baseURL + "services/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarServices(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "services" + Session.getToken();
    return instance.post(url,params);
  },
  editarServices(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "services/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarServices(id) {
    var url = ConfigAPI.baseURL + "services/"+ id + Session.getToken();
    return instance.delete(url);
  },   
  filtrarService(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "services/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },    
  editarTemplateService(data){
    var params = data    
    var url = ConfigAPI.baseURL + "services/update/template" + Session.getToken();
    return instance.post(url,params);
  },      

  obtenerServicesCustomers() {
    var url = ConfigAPI.baseURL + "services-customers" + Session.getToken();
    return instance.get(url);
  },
  mostrarServicesCustomers(id) {
    var url = ConfigAPI.baseURL + "services-customers/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarServicesCustomers(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "services-customers" + Session.getToken();
    return instance.post(url,params);
  },
  editarServicesCustomers(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "services-customers/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarServicesCustomers(id) {
    var url = ConfigAPI.baseURL + "services-customers/"+ id + Session.getToken();
    return instance.delete(url);
  },   
  filtrarServiceCustomers(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "services-customers/filter/query" + Session.getToken();
    return instance.post(url,params);
  },
  cancelarServicesCustomers(data){
    var params = data    
    var url = ConfigAPI.baseURL + "services-customers/change/status/canceled" + Session.getToken();
    return instance.post(url,params);
  },
  restaurarServicesCustomers(data){
    var params = data    
    var url = ConfigAPI.baseURL + "services-customers/change/status/restore" + Session.getToken();
    return instance.post(url,params);
  },

  obtenerServicesCustomersDetails() {
    var url = ConfigAPI.baseURL + "services-customers-details" + Session.getToken();
    return instance.get(url);    
  },
  filtrarServiceCustomersDetails(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "services-customers-details/filter/query" + Session.getToken();
    return instance.post(url,params);
  },
  renewalServicesCustomersDetails(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "services-customers-details/renewal" + Session.getToken();
    return instance.post(url,params);
  },
  cancelarServicesCustomersDetails(data){
    var params = data    
    var url = ConfigAPI.baseURL + "services-customers-details/change/status/canceled" + Session.getToken();
    return instance.post(url,params);
  },
  restaurarServicesCustomersDetails(data){
    var params = data    
    var url = ConfigAPI.baseURL + "services-customers-details/change/status/restore" + Session.getToken();
    return instance.post(url,params);
  },  
  editarObservacionesServicesCustomersDetails(data){
    var params = data    
    var url = ConfigAPI.baseURL + "services-customers-details/update/observations" + Session.getToken();
    return instance.post(url,params);
  },    
  editarNotificacionesServicesCustomersDetails(data){
    var params = data    
    var url = ConfigAPI.baseURL + "services-customers-details/update/notifications" + Session.getToken();
    return instance.post(url,params);
  }, 
  editarNotificacionesServicesCustomersDetailsLote(data){
    var params = data    
    var url = ConfigAPI.baseURL + "services-customers-details/update/notificationsLote" + Session.getToken();
    return instance.post(url,params);
  },    
  cambiarServicesServicesCustomersDetails(data){
    var params = data    
    var url = ConfigAPI.baseURL + "services-customers-details/change/service" + Session.getToken();
    return instance.post(url,params);
  },      

  obtenerServicesCustomersActivity() {
    var url = ConfigAPI.baseURL + "services-customers-activity" + Session.getToken();
    return instance.get(url);    
  },
  eliminarServicesCustomersActivity(id) {
    var url = ConfigAPI.baseURL + "services-customers-activity/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  obtenerListaPrecios() {
    var url = ConfigAPI.baseURL + "price-list" + Session.getToken();
    return instance.get(url);
  },  
  agregarPrecio(data) {
    var params = data
    var url = ConfigAPI.baseURL + "price-list-services" + Session.getToken();
    return instance.post(url,params);
  },
  editarPrecio(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "price-list-services/"+ data.id + Session.getToken();
    return instance.post(url,params);    
  },
  eliminarPrecio(id) {       
    var url = ConfigAPI.baseURL + "price-list-services/"+ id + Session.getToken();
    return instance.delete(url);
  },    

  obtenerClientes() {
    var url = ConfigAPI.baseURL + "customers" + Session.getToken();
    return instance.get(url);
  },        
}

export default servicesAPI;